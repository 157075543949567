import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

function VideoUploader() {
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const data = urlSearchParams.get("data");

  const onDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file) return;

    // Check file format
    if (file.type !== "video/mp4") {
      toast.error("Fayl formati mp4 bo'lishi kerak!");
      return;
    }

    // Check file size
    if (file.size > 50 * 1024 * 1024) {
      toast.error("Iltimos 50MB kichik video faylni yuklang!");

      return;
    }

    if (file.size <= 20 * 1024 * 1024) {
      toast.info("20MB kichik video fayllarni bot orqali yuklashingiz mumkin!");

      return;
    }

    // Check video duration (optional)

    // Set up formData
    const formData = new FormData();
    formData.append("video", file);
    formData.append("hash", data);

    // Set uploading state to true
    setUploading(true);

    // Make POST request using Axios
    try {
      const response = await axios.post(
        // "http://127.0.0.1:8085/v1/api/upload",
        "https://api.videoenhancer.online/v1/api/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle successful upload
      toast.success(response.data?.message);
    } catch (error) {
      // Handle error
      toast.error(error.response.data?.message);
    } finally {
      // Reset uploading state
      setUploading(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected: (fileRejections) => {
      const errors = fileRejections.map((file) => (
        <div key={file.name}>{file.errors[0].message}</div>
      ));
      setUploadError(errors);
    },
    accept: "video/mp4",
    multiple: false,
    maxSize: 50 * 1024 * 1024,
  });

  return (
    <div className='w-[100vw] h-[100vh] flex gap-5 flex-col justify-center items-center'>
      <p className='w-[400px] text-center'>
        {
          "20MB dan yuqori va 50MB kichik bo'lgan videolarni yuklashingiz mumkin."
        }
      </p>
      <div
        {...getRootProps()}
        className='w-[400px] h-[400px] bg-neutral-100 rounded-3xl border-dashed border-[2px] text-center flex justify-center items-center'
      >
        <input disabled={uploading} {...getInputProps()} />
        {isDragActive ? (
          <p>Videoni joylang...</p>
        ) : (
          <p>Drag & drop a video here, or click to select one</p>
        )}
      </div>
      {uploadError && <div className='error'>{uploadError}</div>}
      {uploading && <p>{"Fayl yuklanmoqda..."}</p>}
      <a className='text-white p-3 bg-sky-500 rounded-full cursor-pointer'>
        {"Botga qaytish"}
      </a>
    </div>
  );
}

export default VideoUploader;

const dropzoneStyles = {
  border: "2px dashed #cccccc",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};
