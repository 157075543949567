import logo from "./logo.svg";
import "./App.css";
import VideoUploader from "./components/VideoUploader";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <div>
      <VideoUploader />
      <ToastContainer
        // style={{ fontWeight: 500, color: "black" }}
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />
    </div>
  );
}

export default App;
